import { template as template_d19a63516e3c479c9c599c9adf0a90f3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d19a63516e3c479c9c599c9adf0a90f3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
