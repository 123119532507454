import { template as template_97e8d7a70e934a5584c091346633345e } from "@ember/template-compiler";
const FKText = template_97e8d7a70e934a5584c091346633345e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
