import { template as template_af4448c6784444afa454ef7905b1fe5c } from "@ember/template-compiler";
const SidebarSectionMessage = template_af4448c6784444afa454ef7905b1fe5c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
