import { template as template_e65c1376e8a24d08be6f899cdb6eaf21 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e65c1376e8a24d08be6f899cdb6eaf21(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
