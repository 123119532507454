import { template as template_99a750b8e4664e42be484cadeadd661f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_99a750b8e4664e42be484cadeadd661f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
